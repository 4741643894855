import React from "react"
import { graphql, useStaticQuery } from "gatsby"  
import { Image } from "../atoms/Image"
import { TransitionLink } from "@atoms/Link"
import { globalHistory, navigate } from '@reach/router'

import { useRecoilState } from "recoil"
import { ModalMenuState } from '@status/ModalMenuState'

import * as css from "@css/layouts/l-header.module.styl"

export default function Header()
{   
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "Common__logo__main@2x.png" }) {
                publicURL
                childImageSharp {
                    gatsbyImageData(
                        width: 169
                        placeholder: BLURRED
                        formats: [AUTO]
                        quality: 100
                    )
                }
            }
            logoSvg : file(relativePath: { eq: "Common__logo__main.svg" }) {
                publicURL
            }
            logoSpSvg : file(relativePath: { eq: "Common__logo__main-sp.svg" }) {
                publicURL
            }
            menu : file(relativePath: { eq: "Common__icn__menu.svg" }) {
                publicURL
            }
            menuClose : file(relativePath: { eq: "Common__icn__menu-close.svg" }) {
                publicURL
            }
            cart : file(relativePath: { eq: "Common__icn__cart.svg" }) {
                publicURL
            }
        }
    `)
    const [isOpen, setIsOpen] = useRecoilState(ModalMenuState)
    const onMneuHandler = () => {
        if( isOpen ){
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }
    const { location } = globalHistory
    const cartUrl = `${process.env.GATSBY_MODD_API_CART}${location.href}`
    return(
        <header className={css.lHeader} id="header">
            <div className={css.lHeader__inner}>
                <div className={css.lHeader__left}>
                    <TransitionLink className={css.aLogoPc} to={'/'}>
                        {/* <Image data={q.logo.childImageSharp} alt="L&P FACTORY"/> */}
                        <img src={q.logoSvg.publicURL} alt="L&P FACTORY"/>
                    </TransitionLink>
                    <TransitionLink className={css.aLogoSp} to={'/'}>
                        <img src={q.logoSpSvg.publicURL} alt="L&P FACTORY"/>
                    </TransitionLink>
                </div>
                <div className={css.lHeader__right}>
                    <TransitionLink className={css.aCart} to={cartUrl}>
                        <img src={q.cart.publicURL} alt="cart"/>
                    </TransitionLink>
                    <div className={css.aMenu} onClick={onMneuHandler}>
                        { !isOpen ?
                            <img src={q.menu.publicURL} alt="menu"/>
                            :
                            <img src={q.menuClose.publicURL} alt="close"/>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}

