import { globalHistory, navigate } from '@reach/router'
import { isBrowser,logout } from '@src/services/auth'

const loginUrl = () => {
    return `${process.env.GATSBY_MODD_URL_LOGIN}${process.env.GATSBY_MODD_URL_HOME}`
}
const registerUrl = () => {
    return `${process.env.GATSBY_MODD_URL_REGISTER}${process.env.GATSBY_MODD_URL_HOME}`
}
// Hook
const menuData = [
    ['ニュース','/news/'],
    ['城田優 公式サイト', process.env.GATSBY_MODD_URL_HOME],
    ['城田優 OFFICIAL FAN CLUB', process.env.GATSBY_MODD_URL_FANCRAB],
]

const ItemCategoryData = [
    ['カテゴリー', '/'],
    ['カテゴリー', '/'],
    ['カテゴリー', '/'],
    ['カテゴリー', '/'],
    ['カテゴリー', '/'],
]

const menuData2 = [
    ['利用規約', process.env.GATSBY_MODD_URL_TERM],
    ['FAQ', process.env.GATSBY_MODD_URL_HELP],
    ['お問い合わせ', process.env.GATSBY_MODD_URL_CONTACT],
    ['プライバシーポリシー', process.env.GATSBY_MODD_URL_PRIVACY],
    ['特定商取引法に基づく表示', process.env.GATSBY_MODD_URL_TOKUTEI],
]

const footerMenuData = [
    ['利用規約', process.env.GATSBY_MODD_URL_TERM],
    ['FAQ', process.env.GATSBY_MODD_URL_HELP],
    ['お問い合わせ', process.env.GATSBY_MODD_URL_CONTACT],
    ['プライバシーポリシー', process.env.GATSBY_MODD_URL_PRIVACY],
    ['特定商取引法に基づく表示', process.env.GATSBY_MODD_URL_TOKUTEI],
    ['城田優 公式サイト', process.env.GATSBY_MODD_URL_HOME],
    ['城田優 OFFICIAL FAN CLUB', process.env.GATSBY_MODD_URL_FANCRAB],
]

const loginHandler = () => {
    const { location } = globalHistory
    //urlパラメータが含まれていたら削除
    const url = location.href.replace(/\?.*$/, "");
    if (isBrowser()) {
        window.location.href = `${process.env.GATSBY_MODD_URL_LOGIN}${url}`
    }
}
const registerHandler = () => {
    const { location } = globalHistory
    const url = location.href.replace(/\?.*$/, "");
    if (isBrowser()) {
        window.location.href = `${process.env.GATSBY_MODD_URL_REGISTER}${url}`
    }
}
const logoutHandler = ({cb}) => {
    logout({
        callback: () => {
            if(cb){
                cb()
            }
            if (isBrowser()) {
                window.location.href = `${process.env.GATSBY_MODD_URL_LOGOUT}`
            }
        }
    })
}

export { menuData, menuData2, footerMenuData, ItemCategoryData, loginHandler, registerHandler, logoutHandler, loginUrl, registerUrl }