import React from "react"
import { graphql, useStaticQuery } from "gatsby"  
import { Image } from "@atoms/Image"
import { TransitionLink } from "@atoms/Link"
import * as css from "@css/layouts/l-footer.module.styl"
import { footerMenuData } from "@utility/UrlData"

export default function Footer()
{ 
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "Common__logo__lp@2x.png" }) {
                publicURL
                childImageSharp {
                    gatsbyImageData(
                        width: 300
                        placeholder: BLURRED
                        formats: [AUTO]
                        quality: 100
                    )
                }
            }
        }
    `)
    
    return(
        <footer className={css.lFooter}>
            <div className={css.lFooter__block1}>
                <div className={css.aInner}>
                    <div className={css.aLogo}>
                        <Image data={q.logo.childImageSharp} alt="L&P FACTORY"/>
                    </div>
                </div>
            </div>
            <div className={css.lFooter__block2}>
                <div className={css.aInner}>
                    <nav className={css.pFooterMenu}>
                        <ul>
                            {footerMenuData.map( (val, i) => {
                                return(
                                    <li key={i}>
                                        <TransitionLink to={val[1]}>{val[0]}</TransitionLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    <p className={css.aText}>当サイトにて販売した商品の売上の一部は、各支援団体に寄付させていただきます。</p>
                    <div className={css.aCopyright}>
                        © {new Date().getFullYear()} LIVE IN PEACE. All Rights Reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}