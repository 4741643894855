import React from "react"
import { navigate } from 'gatsby-link'
import { globalHistory } from '@reach/router'
import { useTransition } from '@layouts/TransitionLayout'
import { SmoothScroll } from '@hooks/useSmoothScroll'
import { ModalMenuState } from '@status/ModalMenuState'
import { useRecoilState } from "recoil"

// import { useStore } from '@hooks/useStore'
const TransitionLink = ({
	children,
	to,
	blank,
	scroll,
	onClick,
	...other
}) => {
	// let modalMenuValue = useRecoilValue(modalMenuState)
	// let setModalMenuValue = useSetRecoilState(modalMenuState)
	const [isOpen, setIsOpen] = useRecoilState(ModalMenuState)
	const { transitionState, setTranstionState, duration } = useTransition()

	let isInternal = /^\/(?!\/)/.test(to)
	let isHash = /^(!?#)/.test(to)
	// const { appStore } = useStore()

	if (isHash || scroll) {
		isInternal = true
	}

	const { location } = globalHistory

	const onClickHandler = (e) => {
		e.stopPropagation()
		e.preventDefault()
		if( transitionState !== 0){ return }
		if (location.pathname === to || !to) {
			if (scroll) {
				// console.log(scroll)
				onClick && onClick()
				SmoothScroll(scroll)
			} else {
				onClick && onClick()
				SmoothScroll('#root-container')
			}
			return
		} else {

			onClick && onClick()
			setTranstionState(1)
			setIsOpen(false)
			// if( modalMenuValue === 1){
			// 	setModalMenuValue(0)
			// }
			setTimeout(() => {
				navigate(`${to}`)
				if (scroll) {
					setTimeout(() => {
						SmoothScroll(scroll)
					}, 1000)
				}
			}, duration * 1000)
		}
	}

	// useEffect(()=>{
	// })

	return (
		<>
		{ isInternal ?
				<a
					href={to}
					onClick={onClickHandler}
					{...other}
				>
					{children}
				</a>
			:
				<a href={to} target={blank && "_blank"} {...other}>
					{children}
				</a>
		}
		</>
	)
}
export { TransitionLink }