import React, { useEffect, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'
import { gsap, Power4 } from 'gsap'

import { TransitionLink } from "@atoms/Link"
import { menuData, footerMenuData, ItemCategoryData, menuData2} from '@utility/UrlData'
import { ModalMenuState } from '@status/ModalMenuState'
import { useRecoilState } from "recoil"

import * as css from "@css/layouts/l-modal-menu.module.styl"
import { loginHandler, loginUrl, registerHandler, registerUrl, logoutHandler } from "@src/utility/UrlData"
import { getTicket } from "@src/services/auth"

export default function ModalMenu()
{
    const q = useStaticQuery(graphql`
        query {
            cat: allWpCategory{
                edges {
                    node {
                        name
                        slug
                    }
                }
            }
            catMenu: wpMenu(name: {eq: "カテゴリメニュー"}) {
                menuItems {
                  nodes {
                    id
                    label
                    order
                  }
                }
            }
            menu : file(relativePath: { eq: "Common__icn__menu.svg" }) {
                publicURL
            }
        }
    `)
    let catList = useMemo(()=>{
        let res = {}
        let res2 = []
        q.catMenu && q.catMenu.menuItems.nodes.map( (node) =>{
            // console.log('test',node)'
            res[node.label] = node
        })
        q.cat.edges.map( ({node}) =>{
            console.log('test',node.name)
            if( node.name !== "None" ){
                res[node.name] = { slug: node.slug, ...res[node.name] }
            }
        })
        Object.keys(res).map((key)=>{
            console.log('test2',key)
            const { order } = res[key] 
            res2[order-1] = res[key]
        })
        return res2
    },[])
    console.log('test',q)
    let size = 40
    let borderWidth = 2
    let borderMargin = 9
    let borderTopPosition = size/2-borderWidth/2-borderMargin/2
    let borderBottomPosition = size/2-borderWidth/2+borderMargin/2

    const [isOpen, setIsOpen] = useRecoilState(ModalMenuState)
    // const [isRuning, setIsRuning] = React.useState(false)

    let filColor = ["#ffffff","#ffffff"]

    // const buttonRef = React.useRef()
    // const topBorderRef = React.useRef()
    // const bottomBorderRef = React.useRef() 
    const menuRef = React.useRef()
    // const bgRef = React.useRef()

    //0: closed 1:opend 2: transing

    // React.useEffect(()=>{
    //     appStore.modalMenu = isOpen
    // },[isOpen])

    // React.useEffect(() =>{

    //     const onComplate = () => {
    //         setIsRuning(false)
    //     }

    //     if ( isOpen === 0){
    //     } else if( isOpen === 1 ) {
    //     }
    // },[isOpen, isRuning.current])

    // React.useEffect(()=>{
    //     console.log('update...')
    // })

    const menuHandler = ()=>{
        // if (isRuning === true) { return }
        if (!isOpen){
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
        console.log('hoge',isOpen)
    }
    // console.log("====>",getTicket()
    useEffect(()=>{
        console.log("hoge",isOpen)
        // menuRef.current.style.display = isOpen ? 'block' : 'none'
    },[isOpen])
    // ['ログイン', loginUrl()],
    // ['新規会員登録', registerUrl()],
    let isLogind = getTicket()
    return (
            <nav
                className={css.lModalMenu}
                style={{display: isOpen ? 'block' : 'none'}}
                ref={menuRef}
            >
                <div className={css.aInner}>
                    <div className={css.lModalMenu__list}>
                        <ul>
                            <li>
                                <a onClick={() => {
                                        menuHandler()
                                        if( isLogind ){
                                            logoutHandler({cb: ()=>{}})
                                        } else {
                                            loginHandler()
                                        }
                                }}>
                                    <div className={css.aInner}>
                                        <p className={css.aTitle1}>{ isLogind ? 'ログアウト' : 'ログイン'}</p>
                                    </div>
                                </a>
                            </li>
                            { !isLogind && <li>
                                <a onClick={() => {
                                    menuHandler()
                                    registerHandler()
                                }}>
                                    <div className={css.aInner}>
                                        <p className={css.aTitle1}>新規会員登録</p>
                                    </div>
                                </a>
                            </li> }
                            { isLogind && <li>
                                <a href={process.env.GATSBY_MODD_URL_ORDER}>
                                    <div className={css.aInner}>
                                        <p className={css.aTitle1}>注文履歴</p>
                                    </div>
                                </a>
                            </li>}
                            {menuData.map(( arr, i) => {
                                return (
                                    <li key={i} className={css.lModalMenu__list__item}>
                                        <TransitionLink to={arr[1]} onClick={menuHandler}>
                                            <div className={css.aInner}>
                                                <p className={css.aTitle1}>{arr[0]}</p>
                                            </div>
                                        </TransitionLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={css.lModalMenu__list}>
                        <h5 className={css.aLabel}>アイテムを探す</h5>
                        <ul>
                        {catList && catList.map(( node, i) => {
                            const { label, slug } = node
                                if( slug === 'none') return
                                return (
                                    <li key={i} className={css.lModalMenu__list__item}>
                                        <TransitionLink to={`/items/${slug}`} onClick={menuHandler}>
                                            <div className={css.aInner}>
                                                <p className={css.aTitle1}>{label}</p>
                                            </div>
                                        </TransitionLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={css.lModalMenu__list}>
                        <h5 className={css.aLabel}>その他</h5>
                        <ul>
                        {menuData2.map(( arr, i) => {
                                return (
                                    <li key={i} className={css.lModalMenu__list__item}>
                                        <TransitionLink to={arr[1]} onClick={menuHandler}>
                                            <div className={css.aInner}>
                                                <p className={css.aTitle1}>{arr[0]}</p>
                                            </div>
                                        </TransitionLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </nav>
            
    )
}
