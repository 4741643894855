import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    // storage: sessionStorage
})

export const userLoginState = atom({
    key: 'userLogin',
    default: false
})

export const userInfoState = atom({
    key: 'userInfo',
    default: {
        memberId: {},
        info: {},
    },
    effects_UNSTABLE: [persistAtom],
})